import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./components/Dashboard"),
      meta: { keepAlive: false },
    },
    {
      path: "/clubs",
      name: "Clubs",
      component: () => import("./components/Clubs"),
      meta: { keepAlive: false },
    },

    {
      path: "/class",
      name: "class",
      component: () => import("./components/ClassList"),
      meta: { keepAlive: true },
    },
    {
      path: "/CreateStudent",
      name: "CreateStudent",
      component: () => import("./components/CreateStudent"),
    },
    {
      path: "/Categories",
      name: "Categories",
      component: () => import("./components/Categories"),
      meta: { keepAlive: true },
    },
    {
      path: "/CreateDate",
      name: "CreateDate",
      component: () => import("./components/CreateDate"),
      meta: { keepAlive: true },
    },

    {
      path: "/Statistic",
      name: "Statistic",
      component: () => import("./components/Statistic"),
      meta: { keepAlive: false },
    },
    {
      path: "/Violations",
      name: "Violations",
      component: () => import("./components/Violations"),
      meta: { keepAlive: false },
    },
    {
      path: "/Receipt",
      name: "Receipt",
      component: () => import("./components/Receipt"),
      meta: { keepAlive: false },
    },
    {
      path: "/",
      name: "login",
      component: () => import("./components/login").then((m) => m.default),
      meta: { keepAlive: false },
    },
    {
      path: "/GeneralSummary",
      name: "GeneralSummary",

      component: () => import("./components/GeneralSummary"),
      meta: { keepAlive: false },
    },
    {
      path: "/registryChange",
      name: "registryChange",

      component: () => import("./components/registryChange"),
      meta: { keepAlive: false },
    },
    {
      path: "/TableAttendanceDataSummary",
      name: "TableAttendanceDataSummary",
      props: true,

      component: () => import("./components/TableAttendanceDataSummary"),
      meta: { keepAlive: true },
    },
    {
      path: "/OrderCategory",
      name: "OrderCategory",
      props: true,

      component: () => import("./components/OrderCategory"),
      meta: { keepAlive: true },
    },
    {
      path: "/sampleDocuments",
      name: "sampleDocuments",
      props: true,

      component: () => import("./components/sampleDocuments"),
      meta: { keepAlive: true },
    },
    {
      path: "/PostSettings",
      name: "PostSettings",

      component: () => import("./components/PostSettings"),
      meta: { keepAlive: false },
    },
    {
      path: "/FoodCompensation",
      name: "FoodCompensation",
      props: true,

      component: () => import("./components/FoodCompensation"),
      meta: { keepAlive: true },
    },
    {
      path: "/FoodTypeWeekSettings",
      name: "FoodTypeWeekSettings",
      props: true,

      component: () => import("./components/FoodTypeWeekSettings"),
      meta: { keepAlive: false },
    },
    {
      path: "/globalSettings",
      name: "globalSettings",
      props: true,

      component: () => import("./components/globalSettings"),
      meta: { keepAlive: false },
    },
    {
      path: "/DataStudents",
      name: "DataStudents",
      props: true,

      component: () => import("./components/DataStudents"),
      meta: { keepAlive: false },
    },
    {
      path: "/inventoryControlPanel",
      name: "inventoryControlPanel",
      props: true,

      component: () => import("./components/inventoryControlPanel"),
      meta: { keepAlive: false },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = localStorage.getItem("user");
  if (currentUser === null && to.name !== "login") {
    next({ path: "/" });
    if (currentUser) {
      next(false);
    } else {
      next({ path: "/" });
    }
  } else {
    if (currentUser) {
      next();
    } else {
      next();
    }
  }
});

export default router;
