<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="head">
          <slot
            name="header"
            style="
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            "
          >
            <div>Список кружков</div>

            <div v-if="student != undefined">
              <button
                id="btnAddClubs"
                type="button"
                class="btn btn-primary"
                @click="addClubsStydent"
                style="color: white"
              >
                Обновить занятость ученика
              </button>
            </div>
            <div v-else>
              <button
                type="button"
                class="btn-green"
                @click="getClubs"
                aria-label="Close modal"
              >
                Обновить
              </button>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-danger"
                @click="close"
                aria-label="Close modal"
                style="color: white"
              >
                Закрыть
              </button>
            </div>
          </slot>
        </header>
        <div class="modal-body" id="modalDescription">
          <div>
            <div
              style="
                background-color: #f0e68c;
                text-align: center;
                font-size: 18px;
              "
            >
              Добавлять кружки из списка Школа (location: Школа) и
              местонахождением "Школа" самостоятельно запрещено! Эти данные
              заполняет ответственный за "Навигатор дополнительного образования"
              школы.
            </div>
            <div>
              <!--  
              

              <button
                id="btnrecalculateClubStat"
                type="button"
                class="btn btn-primary"
                @click="recalculateClubStat"
                style="color: white"
              >
                Пересчитать статистику
              </button>
              -->

              Выбранные кружки:
              <div
                style="
                  padding: 10px;
                  background-color: white;
                  display: flex;
                  justify-content: baseline;
                  flex-wrap: wrap;
                "
              >
                <ul
                  v-for="(club, index) in selectedClub"
                  :key="index"
                  style="font-size: 12px; display: block; line-height: 0.4"
                >
                  <li>{{ club.name }}</li>
                </ul>
              </div>

              <div>Всего кружков: {{ clubsList.length }}</div>
            </div>

            <v-card-title>
              <v-text-field
                class="search-table"
                v-model="search"
                append-icon="mdi-magnify"
                label="Поиск (введите название кружка\уровня)"
              >
              </v-text-field>
            </v-card-title>
            <v-data-table
              class="row-height-30"
              v-model="selectedClub"
              :headers="headers"
              :items="this.clubsList"
              group-by="location"
              :items-per-page="1500"
              hide-default-footer
              :single-select="false"
              item-key="_id"
              sort-by="level"
              show-select
              :search="search"
            ></v-data-table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";

export default {
  name: "modal",
  props: ["student"],
  data() {
    return {
      search: [],
      selectedClub: [],
      classID: "",
      clubsList: [{ name: "123", location: "Школа" }],
      headers: [
        {
          text: "Название",
          value: "name",
          sortable: true,
        },
        {
          text: "Нахождение",
          value: "location",
          sortable: true,
        },
        {
          text: "Уровень",
          value: "level",
          sortable: true,
        },
        {
          text: "Профиль",
          value: "profile",
          sortable: true,
        },
        {
          text: "Количество детей",
          value: "students.length",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    close() {
      if (this.student != undefined)
        document.getElementById("btnAddClubs").disabled = false;
      this.$emit("close");
      this.$emit("refresh");
    },
    log() {
      console.log(this.student, this.selectedClub, this.clubsList);
    },
    recalculateClubStat() {
      document.getElementById("btnrecalculateClubStat").disabled = true;
      alert("Функция временно отключена");
      //это убирает всех удаленных учеников из клубов
      // TutorialDataService.recalculateClubStat()
      //   .then((response) => {
      //     console.log(response);

      //      alert("УСПЕШНО обновлено", response);
      //     this.getClubs();
      //     document.getElementById("btnrecalculateClubStat").disabled = false;
      //   })
      //   .catch((e) => {
      //     console.log("1111111111", e);
      //   });

      //это должно добавить ученика в клуб если вдруг он туда не добавился
      // TutorialDataService.recalculateClubStudent()
      //   .then((response) => {
      //     console.log(response);
      //     alert("УСПЕШНО обновлено", response);
      //     this.getClubs();

      //     document.getElementById("btnrecalculateClubStat").disabled = false;
      //   })
      //   .catch((e) => {
      //     console.log("1111111111", e);
      //   });
    },

    addClubsStydent() {
      //document.getElementById("btnAddClubs").disabled = true;
      let elements = document.querySelectorAll("#btnAddClubs");

      for (let elem of elements) {
        elem.disabled = true;
      }

      let data = {
        clubsNow: this.selectedClub,
        idStudent: this.student,
      };
      TutorialDataService.addClubsStudent(data)
        .then((response) => {
          alert("УСПЕШНО ОТПРАВЛЕНО", response);
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    async getClubs() {
      this.selectedClub = [];
      await TutorialDataService.getClubs()
        .then((response) => {
          var a = new Array();
          a = Object.values(response.data).sort(
            (a, b) => (a.location > b.location ? 1 : -1),
            (a, b) => (a.level > b.level ? 1 : -1)
          );

          console.log(a);
          this.clubsList = a;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getStudents(data) {
      this.selectedClub = [];
      console.log(data);
      TutorialDataService.findStudentByClassID(data)
        .then((response) => {
          // this.classList.splice(response.data);
          // var a = new Array();
          // a = Object.values(response.data);
          // for (var i = 0; i < a.length; i++) {
          //   this.$set(this.classList, i, a[i]);
          // }
          let arr = new Array();
          arr = Object.values(response.data);

          for (var i = 0; i < arr.length; i++) {
            if (arr[i]._id == this.student) {
              for (let j = 0; j < this.clubsList.length; j++) {
                for (let k = 0; k < arr[i].Clubs.length; k++) {
                  console.log(
                    "Clubs ID:",
                    arr[i].Clubs[k],
                    this.clubsList[j]._id
                  );
                  if (arr[i].Clubs[k] == this.clubsList[j]._id) {
                    this.selectedClub.push(this.clubsList[j]);
                    console.log("ВЫПОЛНЕНО!", this.selectedClub);
                  }
                }
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getClubs();
    console.log("Clubs GET");
  },
};
</script>
<style>
#head {
  width: auto;
  max-height: 300px;
  position: fixed;
  background: #fff;
  z-index: 1;
}

#modalDescription {
  margin-top: 70px;
  overflow: auto;
}
.v-row-group__header td {
  background-color: #9370db;
  color: white;
  height: 20px;
}
.v-data-table.row-height-30 td {
  height: 30px !important;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  max-height: 650px;

  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
  padding: 2px;
}

.search-table label {
  font-size: 11px;
}
</style>
