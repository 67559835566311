<template>
  <div>
    <div class="flex">
      <div
        v-show="!checkShowTable"
        style="display: flex; justify-content: center; background: transparent"
      >
        <Preloader
          v-if="!checkShowTable && selectedClass.classID != 'admin'"
          ref="Preloader"
        />
      </div>
      <transition name="fade">
        <div
          v-show="checkShowTable || selectedClass.classID === 'admin'"
          class="dashboard-main"
        >
          <div class="main-el">
            <v-progress-linear
              v-if="progress < 100"
              v-model="progress"
            ></v-progress-linear>
            Класс
            <select
              class="form-select"
              v-model="selectedClassID"
              @change="show(selectedClassID)"
              id="class"
            >
              <option
                v-for="(user, index) in sClass"
                :key="index"
                v-bind:value="user"
              >
                {{ user.className }}
              </option>
            </select>
            <label for="className">Выбрать день</label>
            <input
              type="date"
              id="Date"
              class="form-control"
              required
              v-model="sDates.date"
              @change="show()"
            />
            <div
              v-if="
                selectedClassID &&
                sDates.date ===
                  new Date(DateInternet).toISOString().slice(0, 10)
              "
            >
              <button
                @click="loadLastDay()"
                id="loadLast"
                v-if="loadLastDayControl"
                class="btn btn-success"
                style="margin-top: 10px; margin-left: auto; margin-right: auto"
              >
                Загрузить значения прошлого дня
              </button>

              <button
                @click="tomorrowEatingF()"
                id="loadLast"
                class="btn btn-secondary"
                style="margin-top: 10px; margin-left: auto; margin-right: auto"
              >
                Отправить заявку питания на завтра
              </button>
            </div>

            <transition class="reciept-table" name="list">
              <div
                v-if="checkShowTable"
                style="
                  margin-top: 10px;
                  margin-left: auto;
                  margin-right: auto;
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  max-width: 300px;
                "
              >
                <div>Количество порций: {{ sumFoodPortions }}</div>
                <div>"Питался" по категориям:</div>
                <table class="reciept-table">
                  <thead>
                    <tr>
                      <th v-for="(cat, index) in categoryCount" :key="index">
                        {{ cat.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="(cat, index) in categoryCount" :key="index">
                        {{ cat.count }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </transition>

            <div style="display: flex; justify-content: center">
              <Preloader v-if="progress < 100"></Preloader>
            </div>
          </div>
        </div>
      </transition>
      <transition class="reciept-table" name="fade">
        <div v-if="checkShowTable" class="dashboard-main">
          <transition class="reciept-table" name="list">
            <div class="flex-ul">
              <table
                class="reciept-table"
                style="
                  margin-bottom: 10px;
                  margin-left: auto;
                  margin-right: auto;
                  width: 274px;
                "
              >
                <thead>
                  <tr>
                    <th
                      v-for="(caus, index) in causesDefault"
                      :key="index"
                      style="max-width: 180px"
                    >
                      {{ caus.causes }}
                    </th>
                    <th>Отсутствует</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      v-for="(caus, index) in causesDefault"
                      :key="index"
                      style="max-width: 180px"
                    >
                      {{ caus.count }}
                    </td>
                    <td>{{ countedCausesDef }}</td>
                  </tr>
                </tbody>
              </table>

              <div v-if="checkShowTable">
                <table class="reciept-table" style="margin-bottom: 10px">
                  <thead>
                    <tr>
                      <th style="max-width: 180px">Причина</th>
                      <th>Количество</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(caus, index) in causes" :key="index">
                      <td style="max-width: 180px">{{ caus.causes }}</td>
                      <td>{{ caus.count }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </transition>
          <div style="display: flex; justify-content: center; flex-wrap: wrap">
            <transition name="fade" mode="out-in">
              <div v-if="!checkSendData" style="width: 250px">
                <button
                  id="sendData"
                  class="btn btn-primary"
                  @click="send()"
                  color="primary"
                  style="margin-left: auto; margin-right: auto"
                >
                  ОТПРАВИТЬ ДАННЫЕ
                </button>
                <div
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: -15px;
                    text-align: center;
                    font-size: 12px;
                  "
                >
                  Внимание!<br />Дождитесь сообщения, что данные были
                  отправлены!
                </div>
              </div>
              <div v-else-if="loadLastDayCheck">
                <button
                  id="sendData"
                  class="btn btn-primary"
                  @click="send()"
                  color="primary"
                  style="margin-left: auto; margin-right: auto"
                >
                  ОТПРАВИТЬ ДАННЫЕ
                </button>
                <div
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: -15px;
                    text-align: center;
                    font-size: 12px;
                  "
                >
                  Внимание!<br />Дождитесь сообщения, что данные были
                  отправлены!
                </div>
              </div>
              <div v-else>
                <button
                  id="sendData"
                  class="btn btn-danger"
                  color="primary"
                  disabled="true"
                  style="margin-left: auto; margin-right: auto"
                >
                  Данные были отправлены
                </button>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>

    <div
      v-if="loadLastDayCheck"
      style="
        text-align: center;
        color: white;
        font-weight: 800;
        font-size: 20px;
        background-color: blue;
      "
    >
      ПОСЛЕ КОРРЕКТИРОВКИ НЕ ЗАБУДЬТЕ ОТПРАВИТЬ ДАННЫЕ! <br />
      (должно быть сообщение о количестве созданных отметок)
    </div>
    <transition class="reciept-table" name="fade">
      <div v-if="checkShowTable" class="myTable">
        <v-col>
          <v-text-field
            class="search-table"
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск (введите Фамилию\Категорию\Причину)"
          >
          </v-text-field>
          <v-data-table
            :headers="headers"
            :items="this.sClassInput"
            :items-per-page="80"
            hide-default-footer
            :search="search"
            dense
            :item-class="getMyClass"
            id="tablevdata"
          >
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>

            <template v-slot:[`item.date`]="{ item }">
              <div class="select-flex" style="display: flex">
                <div
                  class="select"
                  style="
                    margin: 1px;
                    padding: 1px;
                    flex-shrink: 1;
                    flex-grow: 3;
                  "
                >
                  <select
                    class="form-select"
                    name="marks"
                    v-bind:id="item._id"
                    @change="countMarks(item._id, item, item.countEating)"
                    v-bind:value="item.mark"
                  >
                    <option value=""></option>
                    <option v-for="caus in causes" :key="caus.causes">
                      {{ caus.causes }}
                    </option>
                  </select>
                </div>
                <v-card-actions
                  v-if="item.mark === 'Питался'"
                  style="margin: 1px; padding: 0px; flex-grow: 2"
                >
                  <input
                    @change="countMarks(item._id, item, item.countEating)"
                    type="number"
                    id="typeNumber"
                    disabled
                    class="form-control"
                    v-model="item.countEating"
                  />
                </v-card-actions>
                <v-card-actions
                  id="update"
                  v-show="checkButtonUpdateShow"
                  style="margin: 1px; padding: 1px"
                >
                  <div v-if="item.fullMark">
                    <button
                      v-if="
                        checkButtonUpdateShow &&
                        item.fullMark.change &&
                        !loadLastDayCheck
                      "
                      v-bind:id="item._id + 'update'"
                      @click="updateThisMark(item)"
                      style="padding-left: 1px; padding-right: 1px"
                      :class="{
                        'btn btn-success': item.checkUpdateBtnDisabled,
                        'btn btn-success disabled':
                          !item.checkUpdateBtnDisabled,
                      }"
                    >
                      Обновить
                    </button>
                  </div>
                </v-card-actions>

                <v-card-actions id="createNew" v-if="item.mark === undefined">
                  <button
                    v-bind:id="item._id + 'createNew'"
                    @click="createMarkNewStudent(item)"
                    class="btn btn-success"
                  >
                    Создать
                  </button>
                </v-card-actions>

                <v-card-actions
                  id="changeAdm"
                  v-if="selectedClass.classID === 'admin'"
                >
                  <button
                    v-bind:id="item._id + 'update'"
                    @click="updateThisMarkAdmin(item)"
                    class="btn btn-success"
                    style="padding-left: 1px; padding-right: 1px"
                  >
                    Испр.
                  </button>
                </v-card-actions>
                <div v-if="item.telegram.length > 0">
                  <svg
                    enable-background="new 0 0 128 128"
                    height="40px"
                    width="40px"
                    id="Layer_1"
                    version="1.1"
                    viewBox="0 0 256 256"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="cursor: pointer; margin-left: 5px"
                    @click="telegramDialogActive(item)"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="120"
                        y1="240"
                        x2="120"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stop-color="#1d93d2" />
                        <stop offset="1" stop-color="#38b0e3" />
                      </linearGradient>
                    </defs>
                    <title>
                      Оповещение родителей {{ item.FirstName }}
                      {{ item.LastName }}
                    </title>
                    <circle
                      cx="120"
                      cy="120"
                      r="120"
                      fill="url(#linear-gradient)"
                    />
                    <path
                      d="M81.229,128.772l14.237,39.406s1.78,3.687,3.686,3.687,30.255-29.492,30.255-29.492l31.525-60.89L81.737,118.6Z"
                      fill="#c8daea"
                    />
                    <path
                      d="M100.106,138.878l-2.733,29.046s-1.144,8.9,7.754,0,17.415-15.763,17.415-15.763"
                      fill="#a9c6d8"
                    />
                    <path
                      d="M81.486,130.178,52.2,120.636s-3.5-1.42-2.373-4.64c.232-.664.7-1.229,2.1-2.2,6.489-4.523,120.106-45.36,120.106-45.36s3.208-1.081,5.1-.362a2.766,2.766,0,0,1,1.885,2.055,9.357,9.357,0,0,1,.254,2.585c-.009.752-.1,1.449-.169,2.542-.692,11.165-21.4,94.493-21.4,94.493s-1.239,4.876-5.678,5.043A8.13,8.13,0,0,1,146.1,172.5c-8.711-7.493-38.819-27.727-45.472-32.177a1.27,1.27,0,0,1-.546-.9c-.093-.469.417-1.05.417-1.05s52.426-46.6,53.821-51.492c.108-.379-.3-.566-.848-.4-3.482,1.281-63.844,39.4-70.506,43.607A3.21,3.21,0,0,1,81.486,130.178Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
              </div>
            </template>
          </v-data-table>

          <button
            v-if="selectedClass.classID === 'admin'"
            @click="openForFix()"
            class="btn btn-danger"
          >
            Исправление
          </button>
          <br />
          <br />
          <br />
        </v-col>
      </div>
    </transition>

    <v-dialog v-model="telegramDialog.active" width="590px">
      <v-card>
        <v-card-text style="color: black; font-size: 20px">
          Отправка уведомления для родителей ({{
            telegramDialog.telegram.length
          }}) <br />
          {{ telegramDialog.FirstName }}
          {{ telegramDialog.LastName }}
        </v-card-text>
        <v-card-actions style="display: flex; flex-wrap: wrap">
          <textarea
            v-model="telegramDialog.textMsg"
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            type="text"
            placeholder="Введите свой текст..."
          />
          <v-btn
            v-if="telegramDialog.textMsg.length != 0"
            style="margin-top: 10px"
            color="success"
            block
            @click="sendTextTelegram()"
            >Отправка своего текста</v-btn
          >
          <v-btn
            v-if="telegramDialog.textMsg.length == 0"
            style="margin-top: 10px"
            color="success"
            block
            @click="sendTextTelegram()"
            disabled
            >Отправка своего текста</v-btn
          >
          <br />
          <v-btn
            style="margin-top: 10px; font-color: white"
            color="secondary"
            block
            @click="sendSampleTextTelegram()"
            >Опоздание (отправить шаблонное сообщение)</v-btn
          >
          <br />
          <v-btn
            style="margin-top: 10px"
            color="primary"
            block
            @click="telegramDialog.active = false"
            >Отмена</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="getMenuTomorrowDownload" width="auto">
      <v-card v-if="!tomorrowEating.active">
        <div
          style="
            display: flex;
            justify-content: center;
            background: transparent;
          "
        >
          <Preloader ref="Preloader" />
          <v-btn
            style="margin: 10px"
            color="primary"
            @click="getMenuTomorrowDownload = false"
            >Отмена</v-btn
          >
        </div>
      </v-card>
      <v-card
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10px;
        "
        v-if="tomorrowEating.active"
      >
        <div v-if="tomorrowEating.data" style="color: black; font-size: 20px">
          Заявка на
          {{ new Date(tomorrowEating.data.date).toLocaleDateString() }} ({{
            tomorrowEating.data.day
          }}) {{ selectedClassID.className }}
        </div>
        <div v-if="tomorrowEating.data" style="display: flex; flex-wrap: wrap">
          <table class="reciept-table" style="width: 100%">
            <thead>
              <tr>
                <th>Блюдо</th>
                <th width="40px">Количество</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ tomorrowEating.data.bludo1.name }}
                </td>
                <td>
                  <input
                    v-model="tomorrowEating.bludo1count"
                    type="number"
                    class="form-control form-control-sm"
                    :disabled="tomorrowEating.data.bludo1.name === ''"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {{ tomorrowEating.data.bludo2.name }}
                </td>
                <td>
                  <input
                    v-model="tomorrowEating.bludo2count"
                    type="number"
                    class="form-control form-control-sm"
                    :disabled="tomorrowEating.data.bludo2.name === ''"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {{ tomorrowEating.data.bludo3.name }}
                </td>
                <td>
                  <input
                    v-model="tomorrowEating.bludo3count"
                    type="number"
                    class="form-control form-control-sm"
                    :disabled="tomorrowEating.data.bludo3.name === ''"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {{ tomorrowEating.data.bludo4.name }}
                </td>
                <td>
                  <input
                    v-model="tomorrowEating.bludo4count"
                    type="number"
                    class="form-control form-control-sm"
                    :disabled="tomorrowEating.data.bludo4.name === ''"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style="
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <v-btn
            v-if="!tomorrowEating.checkSend"
            style="margin: 10px"
            color="success"
            @click="sendTomorrowEating()"
            >Отправка Заявки</v-btn
          >
          <v-btn
            v-if="tomorrowEating.checkSend"
            style="margin: 10px"
            color="success"
            @click="sendTomorrowEating()"
            >Обновить</v-btn
          >
          <v-btn
            style="margin: 10px"
            color="primary"
            @click="getMenuTomorrowDownload = false"
            >Отмена</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
@import "../assets/style.css";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  /* transition: opacity 0.3s ease; */
  transition: all 0.1s ease-out;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}

.dashboard-main {
  /* width: 630px; */
  border-radius: 3px;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
  -moz-box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
  box-shadow: 4px 4px 8px 0px rgba(0, 72, 172, 0.25);
  padding: 10px 10px 10px 10px;
  margin: 15px 10px 15px 15px;
}

.v-data-table tbody {
  font-size: 200px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.975rem !important;
}
</style>

<script>
import TutorialDataService from "../services/TutorialDataService";
const Preloader = () => import("./Preloader.vue");
import axios from "axios";
export default {
  components: {
    Preloader: Preloader,
  },
  props: {
    selectedClass: Object,
  },
  data() {
    return {
      telegramDialog: {
        active: false,
        FirstName: "",
        LastName: "",
        telegram: [],
        textMsg: "",
      },
      tomorrowEating: {
        active: false,
        bludo1: { name: "" },
        bludo2: { name: "" },
        bludo3: { name: "" },
        bludo4: { name: "" },
        checkSend: false,
      },
      tempTomorrowEating: [],
      search: "",
      DateInternet: new Date(),
      progress: 100,
      checkShowTable: false,
      checkSendData: false,
      getMenuTomorrowDownload: false,
      sumFoodPortions: 0,
      loadLastDayCheck: false,
      countMark: [],
      studentList: [],
      sClass: [{ className: "123", classID: "sadasd" }],
      sClassInput: [],
      categoryCount: [],
      selectedClassID: [],
      selectedMarks: [],
      sDates: [],
      token: "",
      dateFromServer: "",
      marks: [],
      causesDefault: [
        { id: "1", causes: "Всего", count: 0 },
        { id: "2", causes: "В школе", count: 0 },
      ],
      causes: [{ id: "1", causes: "-", count: 0 }],
      title: "",
      loadLastDayControl: true,
      checkButtonUpdateShow: true,
      headers: [
        {
          value: "index",
          text: "#",
          width: "10px",
        },
        {
          text: "Фамилия",
          value: "FirstName",
          sortable: false,
          width: "85px",
        },
        { text: "Имя", value: "LastName", sortable: false, width: "10px" },
        {
          text: "Категория",
          value: "Category",
          sortable: false,
          width: "30px",
        },
      ],
    };
  },

  methods: {
    // updateManyMark() {
    //   TutorialDataService.createMarksEating()
    //     .then((response) => {
    //       console.log(response);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    async getMenuTomorrow(x, dateBefore, dateFrom) {
      if (x === 0) {
        if (!this.tempTomorrowEating.date) {
          alert(
            "Меню на следующий рабочий день еще не сформировано (или не заполнено в системе). Попробуйте позже"
          );
        }
        return;
      } else {
        dateBefore = Date.parse(dateBefore) + 86400000;
        dateFrom = Date.parse(dateFrom) + 86400000;
        let data = {
          dateBefore: new Date(dateBefore),
          dateFrom: new Date(dateFrom),
        };
        await TutorialDataService.findDayWeekMenuByDateRange(data).then(
          (response) => {
            let temp = Object.values(response.data);
            if (temp.length > 0) {
              if (temp[0].day != "ВЫХОДНОЙ") {
                this.tempTomorrowEating = temp[0];
                this.menuTomorrowInit();
                return temp[0];
              } else {
                this.getMenuTomorrow(
                  x - 1,
                  new Date(dateBefore).toISOString().slice(0, 10),
                  new Date(dateFrom).toISOString().slice(0, 10)
                );
              }
            } else {
              this.getMenuTomorrow(
                x - 1,
                new Date(dateBefore).toISOString().slice(0, 10),
                new Date(dateFrom).toISOString().slice(0, 10)
              );
            }
          }
        );
      }

      return;
    },
    async tomorrowEatingF() {
      this.getMenuTomorrowDownload = true;
      this.tomorrowEating = {
        active: false,
        bludo1: { name: "" },
        bludo2: { name: "" },
        bludo3: { name: "" },
        bludo4: { name: "" },
        checkSend: false,
      };
      await this.getMenuTomorrow(5, this.sDates.date, this.sDates.date);
    },
    menuTomorrowInit() {
      this.tomorrowEating.data = this.tempTomorrowEating;
      this.tomorrowEating.active = true;

      for (let i = 0; i < this.tomorrowEating.data.bludo1.class.length; i++) {
        if (
          this.tomorrowEating.data.bludo1.class[i].className ===
          this.selectedClassID.className
        ) {
          this.tomorrowEating.bludo1count =
            this.tomorrowEating.data.bludo1.class[i].count;
          this.tomorrowEating.checkSend = true;
        }
      }
      for (let i = 0; i < this.tomorrowEating.data.bludo2.class.length; i++) {
        if (
          this.tomorrowEating.data.bludo1.class[i].className ===
          this.selectedClassID.className
        ) {
          this.tomorrowEating.bludo2count =
            this.tomorrowEating.data.bludo2.class[i].count;
          this.tomorrowEating.checkSend = true;
        }
      }
      for (let i = 0; i < this.tomorrowEating.data.bludo3.class.length; i++) {
        if (
          this.tomorrowEating.data.bludo1.class[i].className ===
          this.selectedClassID.className
        ) {
          this.tomorrowEating.bludo3count =
            this.tomorrowEating.data.bludo3.class[i].count;
          this.tomorrowEating.checkSend = true;
        }
      }
      for (let i = 0; i < this.tomorrowEating.data.bludo4.class.length; i++) {
        if (
          this.tomorrowEating.data.bludo4.class[i].className ===
          this.selectedClassID.className
        ) {
          this.tomorrowEating.bludo4count =
            this.tomorrowEating.data.bludo4.class[i].count;
          this.tomorrowEating.checkSend = true;
        }
      }
    },
    async sendTomorrowEating() {
      let data = {
        className: this.selectedClassID.className,
        bludo1: this.tomorrowEating.bludo1count,
        bludo2: this.tomorrowEating.bludo2count,
        bludo3: this.tomorrowEating.bludo3count,
        bludo4: this.tomorrowEating.bludo4count,
        dayID: this.tomorrowEating.data._id,
      };
      await TutorialDataService.addclassDayWeekMenu(data)
        .then(() => {
          // let temp = Object.values(response.data);
          // // this.tomorrowEating.data = temp[0];
          this.tomorrowEating.active = true;
          this.getMenuTomorrowDownload = false;
          alert("заявка отправлена");
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    async telegramDialogActive(item) {
      if (this.token === "") await this.getTokenTelegramBot();
      this.telegramDialog.active = true;
      this.telegramDialog.LastName = item.LastName;
      this.telegramDialog.FirstName = item.FirstName;
      this.telegramDialog.telegram = item.telegram;
      this.telegramDialog.textMsg = "";
    },
    async getTokenTelegramBot() {
      await TutorialDataService.getTelegramToken()
        .then((res) => {
          this.token = res.data;
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },
    async sendTextTelegram() {
      for (let i = 0; i < this.telegramDialog.telegram.length; i++) {
        let msgRes =
          this.telegramDialog.FirstName +
          " " +
          this.telegramDialog.LastName +
          "\n" +
          "Уведомление от классного руководителя" +
          "\n" +
          this.telegramDialog.textMsg;
        await axios
          .post(`https://api.telegram.org/bot${this.token}/sendMessage`, {
            chat_id: this.telegramDialog.telegram[i],
            text: msgRes,
          })
          .then(() => {
            alert("Успешно!");
          })
          .catch((e) => {
            console.log(e.data, "ОШИБКА");
            alert("Ошибка, попробуйте еще раз позже");
          });
      }
    },
    async sendSampleTextTelegram() {
      for (let i = 0; i < this.telegramDialog.telegram.length; i++) {
        let msgRes =
          this.telegramDialog.FirstName +
          " " +
          this.telegramDialog.LastName +
          "\n" +
          "Уведомление от классного руководителя" +
          "\n" +
          "Ребенок опоздал!";
        await axios
          .post(`https://api.telegram.org/bot${this.token}/sendMessage`, {
            chat_id: this.telegramDialog.telegram[i],
            text: msgRes,
          })
          .then(() => {
            alert("Успешно!");
          })
          .catch((e) => {
            console.log(e.data, "ОШИБКА");
            alert("Ошибка, попробуйте еще раз позже");
          });
      }
    },
    openForFix() {
      for (let i = 0; i < this.sClassInput.length; i++) {
        let a = document.getElementById(this.sClassInput[i]._id + "update");
        let b = document.getElementById(this.sClassInput[i]._id);
        a.disabled = false;
        b.disabled = false;
      }
    },
    deleteDublecateMarks() {
      let datas = {
        date: this.sDates.date,
      };
      TutorialDataService.deleteDublecateMarks(datas)
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    clearFullData() {
      this.progress = 0;
      this.checkShowTable = false;
      this.checkSendData = false;
      this.loadLastDayCheck = false;
      this.countMark = [];
      this.sClassInput = [];
      this.categoryCount = [];
      this.selectedMarks = [];
      this.marks = [];
      this.causesDefault = [
        { id: "1", causes: "Всего", count: 0 },
        { id: "2", causes: "В школе", count: 0 },
      ];
      this.causes = [{ id: "1", causes: "-", count: 0 }];
      this.title = "";
      this.headers = [
        {
          value: "index",
          text: "#",
          width: "30px",
        },
        {
          text: "Фамилия",
          value: "FirstName",
          sortable: false,
          width: "90px",
        },
        { text: "Имя", value: "LastName", sortable: false, width: "10px" },
        {
          text: "Категория",
          value: "Category",
          sortable: false,
          width: "30px",
        },
      ];
    },

    //загрузка значений прошлого дня
    async pow(x, data, test, data2) {
      if (x === 0) {
        return;
      }
      if (Object.keys(await test).length > 0) {
        await this.load(data, 1);
        if (this.marks.length > 0) {
          this.loadLastDayCheck = true;
        }
        document.getElementById("sendData").disabled = false;
        return;
      } else {
        data2 = data2 - 86400000;
        var date = new Date(data2);
        data = date.toISOString().slice(0, 10);
        test = await this.findMarksThis(date.toISOString().slice(0, 10));

        this.pow(x - 1, data, test, data2);
      }
      return;
    },

    async loadLastDay() {
      var data = document.getElementById("Date").valueAsNumber;
      data = data - 86400000;
      var data2 = data;
      var date = new Date(data);
      data = date.toISOString().slice(0, 10);
      var test = this.findMarksThis(date.toISOString().slice(0, 10));

      await this.pow(3, data, test, data2);
    },

    countMarks(id, mark) {
      mark.checkUpdateBtnDisabled = true;
      let a = document.getElementById(id);
      let b = a.options[a.selectedIndex || 0].value;
      mark.mark = b;
      mark.date = b;
      this.$set(this.causesDefault[1], "count", 0);
      if (mark.mark === "Питался") {
        // if (mark.fullMark.countEating) {
        //   for (let i = 0; i < this.sClassInput.length; i++) {
        //     if (this.sClassInput[i]._id === id) {
        //       this.sClassInput[i].countEating = mark.fullMark.countEating;
        //     }
        //   }
        // } else {
        // if (!mark.fullMark.countEating) {
        for (let i = 0; i < this.sClassInput.length; i++) {
          if (this.sClassInput[i]._id === id) {
            if (
              this.sClassInput[i].countEating === 0 ||
              this.sClassInput[i].countEating === undefined ||
              this.sClassInput[i].countEating === null
            )
              this.sClassInput[i].countEating = 1;
          }
        }
        // }
      } else {
        for (let i = 0; i < this.sClassInput.length; i++) {
          if (this.sClassInput[i]._id === id) {
            this.sClassInput[i].countEating = 0;
          }
        }
      }

      // for (var j = 0; j < this.causes.length; j++) {
      //   if (a.name === this.causes[j].causes) {
      //     this.causes[j].count -= 1;
      //   }
      //   if (mark.mark === this.causes[j].causes) {
      //     this.causes[j].count += 1;
      //   }
      // }
      // a.name = mark.mark;
      for (let j = 0; j < this.causes.length; j++) {
        this.causes[j].count = 0;
      }
      this.sumFoodPortions = 0;
      for (let i = 0; i < this.sClassInput.length; i++) {
        if (this.sClassInput[i].countEating)
          this.sumFoodPortions += Number(this.sClassInput[i].countEating);
        for (let j = 0; j < this.causes.length; j++) {
          if (this.sClassInput[i].mark === this.causes[j].causes) {
            this.causes[j].count += 1;
          }
        }
      }

      for (let c = 0; c < this.categoryCount.length; c++) {
        this.categoryCount[c].count = 0;
      }
      for (let i = 0; i < this.sClassInput.length; i++) {
        if (
          this.sClassInput[i].mark === "Питался" ||
          this.sClassInput[i].mark === ""
        ) {
          this.$set(
            this.causesDefault[1],
            "count",
            this.causesDefault[1].count + 1
          );
        }

        if (
          this.sClassInput[i].Category !== "" &&
          this.sClassInput[i].mark === "Питался"
        ) {
          for (let c = 0; c < this.categoryCount.length; c++) {
            if (this.sClassInput[i].Category === this.categoryCount[c].name) {
              this.categoryCount[c].count += Number(
                this.sClassInput[i].countEating
              );
            }
          }
        }
      }
    },

    async load(data, checkLoadLast) {
      this.progress = 10;
      let bottomHidden = document.querySelectorAll("#update");

      for (i = 0; i < bottomHidden.length; i++) {
        bottomHidden[i].style = "display: none";
      }
      this.headers[4].text = data;
      this.marks = Array();
      this.marks.splice(0);

      for (var i = 0; i < this.sClassInput.length; i++) {
        this.$set(this.sClassInput[i], "date", false);
      }

      await this.findMarksThis(data);

      await TutorialDataService.getAllCauses().then((response) => {
        this.causes = response.data.map(this.getDisplayCauses);
        this.progress = 50;
      });

      this.$set(this.causesDefault[1], "count", 0);

      await this.showTable();

      for (i = 0; i < this.sClassInput.length; i++) {
        let tempObjCat = new Object();
        tempObjCat.name = this.sClassInput[i].Category;
        tempObjCat.count = 0;
        this.$set(this.sClassInput[i], "checkUpdateBtnDisabled", false); // НОВОЕ!
        this.categoryCount[i] = tempObjCat;

        this.checkButtonUpdateShow = false;

        for (var j = 0; j < this.marks.length; j++) {
          if (this.sClassInput[i]._id === this.marks[j].studentID) {
            this.$set(this.sClassInput[i], "date", this.marks[j].causesID);
            this.$set(this.sClassInput[i], "mark", this.marks[j].causesID);
            this.$set(
              this.sClassInput[i],
              "countEating",
              this.marks[j].countEating
            );
            this.$set(this.sClassInput[i], "fullMark", this.marks[j]);
            for (var c = 0; c < this.causes.length; c++) {
              if (this.causes[c].causes === this.marks[j].causesID) {
                this.$set(this.causes[c], "count", (this.causes[c].count += 1));
              }
            }
          }
        }

        if (this.marks.length === 0) {
          this.$set(this.sClassInput[i], "date", "");
          this.$set(this.sClassInput[i], "mark", "");
          this.$set(this.sClassInput[i], "fullMark", { change: false });
        }
        if (
          this.sClassInput[i].date === "" ||
          this.sClassInput[i].date === "Питался"
        ) {
          this.$set(
            this.causesDefault[1],
            "count",
            this.causesDefault[1].count + 1
          );
        } else {
          this.sClassInput[i].count = false;
        }
      }
      await this.selectedSelect();

      this.$set(this.causesDefault[0], "count", this.sClassInput.length);

      let toDay = new Date(this.DateInternet).toISOString().slice(0, 10);
      if (this.marks.length > 0) {
        if (document.getElementById("loadLast"))
          document.getElementById("loadLast").disabled = true;
        for (i = 0; i < this.marks.length; i++) {
          if (
            this.marks[i].change &&
            toDay === this.marks[i].date.slice(0, 10) &&
            this.marks.length > 0
          ) {
            let a = document.getElementById(this.marks[i].studentID + "update");
            if (a != null) a.disabled = false;
          } else {
            let buttonTemp = document.getElementById(
              this.marks[i].studentID + "update"
            );
            if (buttonTemp) buttonTemp.disabled = true;

            buttonTemp = document.getElementById(this.marks[i].studentID);
            if (buttonTemp) buttonTemp.disabled = true;
          }
        }
      } else {
        this.checkSendData = false;
        if (document.getElementById("loadLast")) {
          document.getElementById("loadLast").disabled = false;
        }

        document.getElementById("sendData").disabled = false;
      }

      if (checkLoadLast) {
        for (let i = 0; i < this.marks.length; i++) {
          document.getElementById(this.marks[i].studentID).disabled = false;
        }
      }

      let newArray = this.categoryCount.filter(function (f) {
        return f.name !== "";
      });
      newArray = newArray.filter(function (f) {
        return f.name !== undefined;
      });
      this.categoryCount = this.removeDuplicates(newArray);

      this.checkButtonHidden();
      await this.fullDownload();

      if (this.sClassInput.length === this.marks.length) {
        this.checkSendData = true;
      }
    },

    selectedSelect() {
      this.sumFoodPortions = 0;
      for (let i = 0; i < this.sClassInput.length; i++) {
        if (this.sClassInput[i].date === "Питался")
          this.sumFoodPortions += Number(this.sClassInput[i].countEating);
        if (
          this.sClassInput[i].date === "Питался" &&
          this.sClassInput[i].Category !== ""
        ) {
          for (let c = 0; c < this.categoryCount.length; c++) {
            if (this.sClassInput[i].Category === this.categoryCount[c].name) {
              this.categoryCount[c].count += this.sClassInput[i].countEating;
            }
          }
        }
      }

      this.progress = 80;
    },

    async findMarksThis(data) {
      var temp1;
      temp1 = { classID: this.selectedClassID.classID, date: data }; //this.sDates.date };
      await TutorialDataService.findMarks(temp1)
        .then((response) => {
          var a = new Array();
          a = Object.values(response.data);
          temp1 = response.data;

          for (var i = 0; i < a.length; i++) {
            if (a[i].createdAt === a[i].updatedAt) {
              a[i].change = true;
            } else {
              a[i].change = false;
            }
            this.$set(this.marks, i, a[i]);
          }
        })
        .catch((e) => {
          console.log(e);
        });

      return temp1;
    },

    async createMarkNewStudent(data) {
      let datas = [];
      if (data.mark === undefined) data.mark = "";
      datas[0] = {
        date: this.sDates.date,
        classID: data.classID,
        studentID: data._id,
        causesID: data.mark,
        cat: data.Category,
        countEating: data.countEating,
      };
      await TutorialDataService.createMarks(datas)
        .then(async (response) => {
          alert("Отметка успешно создана!");
          console.log(response);
          this.retrieveClass();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },
    async updateThisMark(data) {
      for (let i = 0; i < this.sClassInput.length; i++) {
        if (this.sClassInput[i]._id === data._id) {
          if (
            this.sClassInput[i].fullMark.change != undefined &&
            this.sClassInput[i].fullMark != undefined
          ) {
            this.$set(this.sClassInput[i].fullMark, "change", false);
          }
          break;
        }
      }
      var markID;
      for (var j = 0; j < this.marks.length; j++) {
        if (this.marks[j].studentID === data._id) {
          markID = this.marks[j]._id;
          break;
        }
      }
      if (markID === undefined) {
        let datas = [];
        datas[0] = {
          date: this.sDates.date,
          classID: data.classID,
          studentID: data._id,
          causesID: data.mark,
          cat: data.Category,
          countEating: data.countEating,
        };
        await TutorialDataService.createMarks(datas)
          .then(async (response) => {
            console.log("Создано", response);
          })
          .catch((e) => {
            console.log("1111111111", e);
          });
      } else {
        var datas = {
          causes: data.mark,
          countEating: data.countEating,
        };
        TutorialDataService.updateMark(markID, datas)
          .then((response) => {
            console.log("УСПЕШНО ОТПРАВЛЕНО", response);
            alert("Успешно обновлено");
          })
          .catch((e) => {
            console.log("1111111111", e);
          });
      }

      document.getElementById(data._id + "update").disabled = true;

      document.getElementById(data._id).disabled = true;
      document.getElementById(data._id + "update").remove();
    },
    async updateThisMarkAdmin(data) {
      let changeCat = false;

      for (let i = 0; i < this.sClassInput.length; i++) {
        if (this.sClassInput[i]._id === data._id) {
          if (
            this.sClassInput[i].fullMark.change != undefined &&
            this.sClassInput[i].fullMark != undefined
          ) {
            this.$set(this.sClassInput[i].fullMark, "change", false);
          }
          break;
        }
      }
      var markID;
      for (var j = 0; j < this.marks.length; j++) {
        if (this.marks[j].studentID === data._id) {
          markID = this.marks[j]._id;
          break;
        }
      }
      if (data.fullMark != undefined && data.fullMark.cat != data.Category)
        changeCat = confirm(
          "Вы хотите изменить категорию МАРКИ с текущей " +
            data.fullMark.cat +
            " на " +
            data.Category +
            " - текущую категорию ученика?"
        );
      if (markID === undefined) {
        let datas = [];
        datas[0] = {
          date: this.sDates.date,
          classID: data.classID,
          studentID: data._id,
          causesID: data.mark,
          cat: data.Category,
          countEating: data.countEating,
        };
        await TutorialDataService.createMarks(datas)
          .then(async (response) => {
            console.log("Создано", response);
          })
          .catch((e) => {
            console.log("1111111111", e);
          });
      } else {
        var datas = {
          causes: data.mark,
          countEating: data.countEating,
          cat: data.fullMark.cat,
        };
        if (changeCat) {
          datas.cat = data.Category;
        }

        TutorialDataService.updateMarkAdmin(markID, datas)
          .then((response) => {
            console.log("УСПЕШНО ОТПРАВЛЕНО", response);
            alert("Успешно обновлено");
          })
          .catch((e) => {
            console.log("1111111111", e);
          });
      }

      document.getElementById(data._id + "update").disabled = true;

      document.getElementById(data._id).disabled = true;
      document.getElementById(data._id + "update").remove();
    },

    async send() {
      if (confirm("Вы уверены?")) {
        this.progress = 10;
        let temp;
        var data = this.sClassInput;

        var datas = new Array();
        for (var i = 0; i < data.length; i++) {
          if (data[i].mark === undefined || data[i].mark === "") {
            temp = "";

            datas[i] = {
              date: this.sDates.date,
              classID: data[i].classID,
              studentID: data[i]._id,
              causesID: temp,
              cat: data[i].Category,
              countEating: data[i].countEating,
            };
          } else {
            temp = data[i].mark;
            datas[i] = {
              date: this.sDates.date,
              classID: data[i].classID,
              studentID: data[i]._id,
              causesID: temp,
              cat: data[i].Category,
              countEating: data[i].countEating,
            };
          }
        }
        this.progress = 30;
        await TutorialDataService.createMarks(datas)
          .then((response) => {
            if (response.data.message === "OK!") {
              this.sendTelegram();

              alert(
                "Данные были успешно отправлены!\nСоздано: " +
                  response.data.countMarks +
                  "\nЕсли созданных отметок меньше, чем детей в Вашем классе - перепроверьте данные"
              );
              if (response.data.countMarks != this.sClassInput.length) {
                alert(
                  "Если отметки не создавались ранее, возможны ошибки! Перепроверьте данные!"
                );
              }
              this.checkSendData = true;
              this.progress = 50;
              if (this.selectedClass.classID != "admin" && response) {
                this.clearFullData();
                this.progress = 60;
                this.$set(this.headers, 4, {
                  text: this.sDates.date,
                  value: "date",
                  width: "40%",
                  sortable: false,
                });
                this.progress = 80;
                this.show(this.selectedClassID);
              } else {
                this.progress = 100;
                this.show(this.selectedClassID);
              }
            } else {
              alert("ОШИБКА ПРИ ОТПРАВКЕ ДАННЫХ! Попробуйте позже");
            }
          })
          .catch((e) => {
            console.log("1111111111", e);
            alert("ОШИБКА ПРИ ОТПРАВКЕ! Попробуйте позже");
          });
      }
    },

    async sendTelegram() {
      for (let i = 0; i < this.sClassInput.length; i++) {
        if (this.sClassInput[i].telegram.length > 0) {
          for (let j = 0; j < this.sClassInput[i].telegram.length; j++) {
            let temp;
            if (this.sClassInput[i].mark == undefined) {
              temp = "";
            } else {
              temp = this.sClassInput[i].mark;
            }
            let msgRes =
              this.DateInternet +
              " " +
              this.sClassInput[i].FirstName +
              " " +
              this.sClassInput[i].LastName +
              "\n" +
              "Выставлена отметка " +
              temp;
            if (this.sClassInput[i].countEating >= 1) {
              msgRes += " " + this.sClassInput[i].countEating;
            }
            await axios
              .post(`https://api.telegram.org/bot${this.token}/sendMessage`, {
                chat_id: this.sClassInput[i].telegram[j],
                text: msgRes,
              })
              .catch((e) => {
                console.log(e.data, "ОШИБКА");
              });
          }
        }
      }
    },

    show(data) {
      //this.checkSendData = true;

      this.sClassInput = [];
      data = this.selectedClassID;
      TutorialDataService.findStudentByClassID(data)
        .then((response) => {
          this.sClassInput.splice(response.data);
          var a = new Array();
          a = Object.values(response.data);
          for (let i = 0; i < a.length; i++) {
            this.$set(this.sClassInput, i, a[i]);
            this.sClassInput[i].countEating = 0;
          }
        })
        .catch((e) => {
          console.log(e);
        });

      if (this.sDates.date) {
        this.load(this.sDates.date);
      }
    },

    async getDate() {
      let date;
      await TutorialDataService.getDateWorld()
        .then((response) => {
          date = new Date(response.data).toISOString().slice(0, 10);
          this.dateFromServer = response.data;
        })
        .catch(
          (e) =>
            async function () {
              alert("ошибка получения даты");
              date = await this.reserveGetDate();
              console.log(e);
            }
        );

      return date;
    },
    async reserveGetDate() {
      let date;
      await TutorialDataService.getDateWorld()
        .then((response) => {
          date = new Date(response.data).toISOString().slice(0, 10);
          this.dateFromServer = response.data;
        })
        .catch((e) => {
          alert("ошибка получения даты");
          this.getDate();
          console.log(e);
        });

      return date;
    },

    async retrieveClass() {
      //поставить текущую дату
      document.getElementById("Date").value = this.DateInternet;

      this.$set(
        this.sDates,
        "date",
        new Date(this.DateInternet).toISOString().slice(0, 10)
      );
      // добавить колонку с датой и выбором
      this.$set(this.headers, 4, {
        text: this.sDates.date,
        value: "date",
        width: "40%",
        sortable: false,
      });

      //получить список классов
      await TutorialDataService.getAllCLass()
        .then((response) => {
          this.sClass = response.data.map(this.getDisplayClass);
        })
        .catch((e) => {
          console.log(e);
        });

      if (this.selectedClass.classID != "admin") {
        this.selectedClassID = this.selectedClass;
        await this.show();
        document.getElementById("class").disabled = true;
      }
    },

    getDisplayClassID(data) {
      return {
        classID: data.data._id,
        className: data.data.className,
        classLider: data.data.classLider,
      };
    },
    getDisplayCauses(data) {
      return {
        id: data._id,
        causes: data.causes,
        count: 0,
      };
    },

    getDisplayClass(data) {
      return {
        classID: data._id,
        className: data.className,
      };
    },

    checkButtonHidden() {
      let date = new Date(this.dateFromServer);
      date.setHours(date.getHours() - 3);
      if (
        this.sDates.date ===
          new Date(this.DateInternet).toISOString().slice(0, 10) &&
        date.getHours() < 16
      ) {
        this.checkButtonUpdateShow = true;
      } else {
        this.checkButtonUpdateShow = false;
      }
    },

    removeDuplicates(arr) {
      const result = [];
      const duplicatesIndices = [];

      // Перебираем каждый элемент в исходном массиве
      arr.forEach((current, index) => {
        if (duplicatesIndices.includes(index)) return;

        result.push(current);

        // Сравниваем каждый элемент в массиве после текущего
        for (
          let comparisonIndex = index + 1;
          comparisonIndex < arr.length;
          comparisonIndex++
        ) {
          const comparison = arr[comparisonIndex];
          const currentKeys = Object.keys(current);
          const comparisonKeys = Object.keys(comparison);

          // Проверяем длину массивов
          if (currentKeys.length !== comparisonKeys.length) continue;

          // Проверяем значение ключей
          const currentKeysString = currentKeys.sort().join("").toLowerCase();
          const comparisonKeysString = comparisonKeys
            .sort()
            .join("")
            .toLowerCase();
          if (currentKeysString !== comparisonKeysString) continue;

          // Проверяем индексы ключей
          let valuesEqual = true;
          for (let i = 0; i < currentKeys.length; i++) {
            const key = currentKeys[i];
            if (current[key] !== comparison[key]) {
              valuesEqual = false;
              break;
            }
          }
          if (valuesEqual) duplicatesIndices.push(comparisonIndex);
        } // Конец цикла
      });
      return result;
    },

    showTable() {
      this.checkShowTable = true;
    },

    fullDownload() {
      this.progress = 100;
    },
    getMyClass(item) {
      if (item.fullMark != undefined) {
        if (item.fullMark.qr != undefined) {
          if (item.fullMark.qr) return "green";
        }
      } else return "";
    },
  },
  async mounted() {
    this.DateInternet = await this.getDate();
    this.retrieveClass();
  },

  computed: {
    countedCausesDef: function () {
      return this.causesDefault[0].count - this.causesDefault[1].count;
    },
  },
};
</script>

<style scoped>
.green {
  background-color: rgb(200, 255, 237);
}

.form-select {
  height: 38px;
}
.v-card__actions {
  padding: 1px;
}
</style>
